import { getCookie } from '../utility/Utils';

export const getAccessToken = () => {
  let accessToken;
  if (
    (window.location.pathname.includes('/member') ||
      window.location.pathname.includes('/admin')) &&
    localStorage.getItem('isCompanyAdmin') &&
    localStorage.getItem('memberUserId') &&
    localStorage.getItem('memberToken')
  ) {
    accessToken = localStorage.getItem('memberToken');
  } else if (
    window.location.pathname.includes('/admin') &&
    localStorage.getItem('isSuperAdmin') &&
    localStorage.getItem('adminUserId') &&
    localStorage.getItem('adminToken')
  ) {
    accessToken = localStorage.getItem('adminToken');
  } else {
    accessToken = getCookie('token');
  }
  return accessToken;
};
