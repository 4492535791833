import axios from 'axios';
import { showToast, TOASTTYPES } from '@src/utility/toast-helper';
import { handlePageNotFound, setProcessing } from '../redux/common';
import { store } from '../redux/store';
import { getAccessToken } from '../helper/auth.helper';
import { logoutHelper } from '../helper/logout.helper';
export const baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  (config) => {
    // ** Get token from localStorage

    const accessToken = getAccessToken();

    // ** If token is present add it to request's Authorization Header
    if (accessToken) {
      config.headers.Authorization = accessToken;
      // config.headers.Authorization = `JWT ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error?.response?.status === 401) {
      const alreadyLoggedOut = !!error?.config?.url?.includes('/auth/logout');
      logoutHelper(alreadyLoggedOut);
    } else if ([404].includes(error?.response?.status)) {
      if (error?.response?.data?.data?.code === `IN_VALID_REQUEST`) {
        store.dispatch(handlePageNotFound(true));
      }
    }
    throw error;
  }
);

const setLoader = (shouldShow, status) => {
  if (shouldShow) {
    store.dispatch(setProcessing(status));
  }
};

export const axiosPost = (
  url,
  data = {},
  params = {},
  shouldProcess = false,
  options = {}
) => {
  setLoader(shouldProcess, true);
  return axios
    .post(baseURL + url, data, {
      params,
      ...options,
    })
    .then((res) => {
      setLoader(shouldProcess, false);
      return res;
    })
    .catch((error) => {
      setLoader(shouldProcess, false);
      return {
        error: error.response?.data?.message?.text
          ? error.response?.data?.message?.text
          : 'Error while processing',
        errorData: error.response?.data?.message?.errors
          ? error.response?.data?.message?.errors
          : error.response?.data?.message
          ? error.response?.data?.message
          : [],
      };
    });
};

export const axiosGet = (url, params, shouldProcess = false) => {
  setLoader(shouldProcess, true);
  return axios
    .get(baseURL + url, { params })
    .then((res) => {
      setLoader(shouldProcess, false);
      return res;
    })
    .catch((error) => {
      setLoader(shouldProcess, false);
      return {
        error: error.response?.data?.message?.text
          ? error.response?.data?.message?.text
          : 'Error while processing',
      };
    });
};

export const axiosPut = (
  url,
  data = {},
  params = {},
  shouldProcess = false
) => {
  setLoader(shouldProcess, true);
  return axios
    .put(baseURL + url, data, { params })
    .then((res) => {
      setLoader(shouldProcess, false);
      return res;
    })
    .catch((error) => {
      setLoader(shouldProcess, false);

      return {
        error: error.response?.data?.message?.text
          ? error.response?.data?.message?.text
          : 'Error while processing',
      };
    });
};

export const axiosPatch = (url, data = {}, params, shouldProcess = false) => {
  setLoader(shouldProcess, true);
  return axios
    .patch(baseURL + url, data, { params })
    .then((res) => {
      setLoader(shouldProcess, false);
      return res;
    })
    .catch((error) => {
      setLoader(shouldProcess, false);
      return {
        error: error.response?.data?.message?.text
          ? error.response?.data?.message?.text
          : 'Error while processing',
      };
    });
};

export const axiosDelete = (url, params, shouldProcess = false) => {
  setLoader(shouldProcess, true);
  return axios
    .delete(baseURL + url, { params })
    .then((res) => {
      setLoader(shouldProcess, false);
      return res;
    })
    .catch((error) => {
      setLoader(shouldProcess, false);
      return {
        error: error.response?.data?.message?.text
          ? error.response?.data?.message?.text
          : 'Error while processing',
      };
    });
};

export const axiosBaseQuery = async (args) => {
  let result;
  let toastId = '';
  try {
    result = await axios({
      url: baseURL + args.url,
      method: args.method,
      data: args.data,
      params: args.params,
      ...args.extraOptions,
    });

    if (args?.data?.loadingMsg)
      toastId = showToast(TOASTTYPES.loading, '', args?.data?.loadingMsg);

    const toast = result?.data?.toast;
    const message = result?.data?.message;
    const responseType = result?.data?.response_type;

    if (toast && message) {
      if (responseType === 'success') {
        showToast(TOASTTYPES.success, toastId, message);
      } else {
        showToast(TOASTTYPES.error, toastId, message);
      }
    }
    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError;

    const errorMsg =
      result?.error ||
      (err.response?.data?.message?.text
        ? err.response.data.message.text
        : err.response?.data?.message
        ? err.response.data.message
        : 'Error while processing');
    err.error = errorMsg;
    err.data = err?.response?.data?.data || null;

    if (err?.message !== 'canceled') {
      showToast(TOASTTYPES.error, toastId, errorMsg);
    }

    return {
      error: {
        status: err?.response?.status,
        data: err?.response?.data || err?.message,
        message: err?.response?.data?.message || 'Something went wrong...',
      },
    };
  }
};

export default axios;
