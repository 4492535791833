export const CHAT_TYPE = {
  DIRECT: 'direct',
  GROUP: 'group',
};

export const MESSAGE_TYPE = {
  TEXT: 'TEXT',
  IMAGE_AND_VIDEO: 'IMAGE_AND_VIDEO',
  DOCUMENT: 'DOCUMENT',
};

export const CHAT_FILE_TYPES_ACCEPT_STRING =
  '.jpg,.jpeg,.png,.svg,.mp4,.m4v,.avi,.mov,.wmv,.heic,.pdf,.xlsx,.xls,.csv,.mp3,.docx,.doc,.ppt,.pptx,.ods,.odt';
