import { baseURL } from '../api/axios-config';
import Axios from '@src/api/axios-config';
import { store } from '../redux/store';
import { storeToken, storeUser } from '../redux/user';
import { unregister } from '../serviceWorker';

export const logoutHelper = async (alreadyLoggedOut = false) => {
  unregister();
  if (
    !alreadyLoggedOut &&
    (localStorage.getItem('userId') ||
      localStorage.getItem('memberUserId') ||
      localStorage.getItem('adminUserId'))
  ) {
    await Axios({
      url: `${baseURL}/auth/logout`,
      method: 'POST',
    });
  }
  if (
    (window.location.pathname.includes('/member') ||
      window.location.pathname.includes('/admin')) &&
    localStorage.getItem('isCompanyAdmin') &&
    localStorage.getItem('memberUserId') &&
    localStorage.getItem('memberToken')
  ) {
    localStorage.removeItem('isCompanyAdmin');
    localStorage.removeItem('memberUserId');
    localStorage.removeItem('memberToken');
    localStorage.removeItem('group');
    localStorage.removeItem('currentTaskFilters');
    // history.push('/');
    window.location.href = '/';
  } else if (
    window.location.pathname.includes('/admin') &&
    localStorage.getItem('isSuperAdmin') &&
    localStorage.getItem('adminUserId') &&
    localStorage.getItem('adminToken')
  ) {
    localStorage.removeItem('isSuperAdmin');
    localStorage.removeItem('adminUserId');
    localStorage.removeItem('adminToken');
    localStorage.removeItem('group');
    localStorage.removeItem('currentTaskFilters');
    // history.push('/');
    window.location.href = '/';
  } else {
    const isMemberToken = localStorage.getItem('memberToken');
    document.cookie = `token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('isCompanyAdmin');
    localStorage.removeItem('memberUserId');
    localStorage.removeItem('memberToken');
    localStorage.removeItem('isSuperAdmin');
    localStorage.removeItem('adminUserId');
    localStorage.removeItem('adminToken');
    localStorage.removeItem('group');
    localStorage.removeItem('currentTaskFilters');

    if (!isMemberToken) {
      store.dispatch(storeUser({}));
      store.dispatch(storeToken(null));
    } else {
      window.location.reload();
      // history.push('/');
      window.location.href = '/';
    }
  }
};
