import { createSlice } from '@reduxjs/toolkit';
import { CHAT_TYPE } from '../views/Admin/Chat/constants';

export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    chatList: [],
    currentChatDetail: null,
    chatType: CHAT_TYPE.DIRECT,
  },
  reducers: {
    setChatList: (state, action) => {
      state.chatList = [...action.payload];
      return state;
    },
    setAddNewChat: (state, action) => {
      state.chatList = [action.payload, ...state.chatList];
      return state;
    },
    setUpdateNewChat: (state, action) => {
      const { chatId, updatedData } = action.payload;
      state.chatList = state.chatList.map((obj) =>
        obj._id === chatId ? { ...obj, ...updatedData } : obj
      );
      return state;
    },
    setCurrentChatDetail: (state, action) => {
      state.currentChatDetail = action.payload;
      return state;
    },
    setChatType: (state, action) => {
      state.chatType = action.payload;
      return state;
    },
  },
});

export const {
  setChatList,
  setAddNewChat,
  setUpdateNewChat,
  setCurrentChatDetail,
  setChatType,
} = chatSlice.actions;

export const getChatReduxState = (state) => state.chat;

export default chatSlice.reducer;
